import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByDateRange",
})
export class FilterByDateRangePipe implements PipeTransform {
  transform(array: any[], initialDate: string, finalDate: string): any[] {
    const filteredArray = [];
    if (initialDate && finalDate) {
      for(const data of array){
        const date = new Date(data.date);
        const iniDate = new Date(initialDate);
        const finDate = new Date(finalDate);

        if(date.getTime() >= iniDate.getTime() && date.getTime() <= finDate.getTime()){
          filteredArray.push(data);
        }
      }
      return filteredArray;
    }
    return array;
  }
}
