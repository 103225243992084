import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], filterString: string, property: string): any[] {
    if (!value || !filterString) {
      return value;
    }
    let filtered: any[] = [];
    for (let data of value) {
      if (data[property].toLowerCase().includes(filterString.toLowerCase())) {
        filtered.push(data);
      }
    }
    return filtered;
  }
}
