import { Action, State, StateContext, Selector } from "@ngxs/store";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { Subtask } from "../interfaces/subtask";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";
import { tap } from "rxjs";

export class SendNewSubtask {
  static readonly type = "[Subtask] SendNewSubtask";
  constructor(public readonly subtask: Subtask) {}
}

export class GetSubtasks {
  static readonly type = "[Subtask] GetSubtasks";
  constructor() {}
}

export class GetSubtaskById {
  static readonly type = "[Subtask] GetSubtaskById";
  constructor(public readonly id: string) {}
}

export class EditSubtask {
  static readonly type = "[Subtask] EditSubtask]";
  constructor(public readonly id: string, public readonly subtask: Subtask) {}
}

export class DeleteSubtask {
  static readonly type = "[Subtask] DeleteSubtask]";
  constructor(public readonly id: string) {}
}

const subtasksCollection = environment.production ? "subtask" : "dev-subtask";

@State<Subtask>({
  name: "subtask",
})
@Injectable()
export class SubtaskState {
  constructor(
    private afs: AngularFirestore,
    private snack: MatSnackBar,
    private cookieService: CookieService
  ) {}

  @Selector()
  static getSubtask({ subtasks }: Subtask): Subtask[] {
    return subtasks;
  }

  @Selector()
  static getSubtaskById({ subtask }: Subtask): Subtask {
    return subtask;
  }

  @Action(SendNewSubtask)
  SendNewSubtask({}: StateContext<Subtask>, { subtask }: SendNewSubtask) {
    const id = this.afs.createId();
    const dateform = new Date();
    subtask.createdAt = dateform.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));
    subtask.createdBy = user.username;
    try {
      this.afs
        .collection(subtasksCollection)
        .doc(id)
        .set({
          id,
          ...subtask,
        });
      this.snack.open("Subtarea creada correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(GetSubtasks)
  getSubtaks({ patchState, getState }: StateContext<Subtask>, {}: GetSubtasks) {
    const { subtasks } = getState();

    if (!subtasks?.length)
      return this.afs
        .collection<Subtask>(subtasksCollection)
        .valueChanges()
        .pipe(tap((subtasks) => patchState({ subtasks: subtasks })));

    return null;
  }

  @Action(GetSubtaskById)
  getSubtaskById(
    { patchState }: StateContext<Subtask>,
    { id }: GetSubtaskById
  ) {
    const subs = this.afs
      .collection<Subtask>(subtasksCollection)
      .doc(id)
      .valueChanges()
      .pipe(
        tap((subtask) => {
          patchState({ subtask });
          subs.unsubscribe();
        })
      )
      .subscribe();
  }

  @Action(EditSubtask)
  async EditSubtask({}: StateContext<Subtask>, { id, subtask }: EditSubtask) {
    const dateform = new Date();
    subtask.updatedAt = dateform.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));
    subtask.updatedBy = user.username;
    try {
      await this.afs
        .collection(subtasksCollection)
        .doc(id)
        .update({
          id,
          ...subtask,
        });
      this.snack.open("Subtarea actualizada correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(DeleteSubtask)
  async DeleteSubtask({}: StateContext<Subtask>, { id }: DeleteSubtask) {
    try {
      await this.afs.collection(subtasksCollection).doc(id).delete();
      this.snack.open("Subtarea borrada correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
