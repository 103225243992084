import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";

@Injectable({
  providedIn: "root",
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private cookieService: CookieService, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.cookieService.get("user")) {
      return true;
    } else {
      this.store.dispatch(new Navigate(["/login"]));
      return false;
    }
  }
}
