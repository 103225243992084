import { Pipe, PipeTransform } from "@angular/core";
import { Report } from "../interfaces/report";

@Pipe({
  name: "sortReportByNumber",
})
export class SortReportByNumberPipe implements PipeTransform {
  transform(reports: Report[]): Report[] {
    if (reports) {
      reports.sort((firstElement: any, secondElement: any) => {
        if (firstElement["reportNumber"] > secondElement["reportNumber"]) {
          return -1;
        } else if (
          firstElement["reportNumber"] < secondElement["reportNumber"]
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return reports;
  }
}
