import { ValueTypes } from "./../interfaces/report";
import { Pipe, PipeTransform } from "@angular/core";
import { ReportSubtask, Subtask } from "../interfaces/subtask";

@Pipe({
  name: "filterPdf",
})
export class FilterPdfItemsPipe implements PipeTransform {
  transform(array: ReportSubtask[], mode: boolean): ReportSubtask[] {
    const filterSubtaks: ReportSubtask[] = [];
    if (mode) {
      for (let data of array) {
        if (data.type === ValueTypes.MULTIPLE) {
          if (
            data["value"].approved ||
            data["value"].alert ||
            data["value"].failed ||
            data["addicionalNote"]
          ) {
            filterSubtaks.push(data);
          }
        } else if (data.type === ValueTypes.STRING) {
          if (
            data["value"].after ||
            data["value"].before ||
            data["addicionalNote"]
          )
            filterSubtaks.push(data);
        }
      }
      return filterSubtaks;
    } else {
      return array;
    }
  }
}
