<div class="grid mt-20" [formGroup]="reportHeaderForm">
  <mat-form-field appearance="outline">
    <mat-label>Dirección</mat-label>
    <textarea
      matInput
      style="height: 100px; max-height: 100px"
      class="form-field mb-15"
      type="text"
      formControlName="address"
      matTextareaAutosize
    >
    </textarea>

    <mat-error> La dirección es <strong>requerida</strong> </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Teléfono</mat-label>
    <input
      matInput
      type="text"
      required
      formControlName="phone"
      autocomplete="off"
      title="Teléfono"
    />

    <mat-error> El número de telefono es <strong>requerido</strong> </mat-error>
  </mat-form-field>

  <div class="w-90 w-s-90 w-xs-100 content-right mt-10 mb-20 mr-10">
    <button
      mat-raised-button
      color="primary"
      [ngClass]="{ 'primary-button-disable': reportHeaderForm.invalid }"
      (click)="saveReportHeaderInformation()"
      [disabled]="reportHeaderForm.invalid"
      class="w-15 w-s-25 w-xs-35"
    >
      Guardar
    </button>
  </div>
</div>

<div *ngIf="loading" class="loading-icon">
  <mat-spinner></mat-spinner>
</div>
