import { Action, State, StateContext, Selector, Store } from "@ngxs/store";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { inject, Injectable } from "@angular/core";
import { Photo, Report, ReportSettings } from "../interfaces/report";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { Navigate } from "@ngxs/router-plugin";
import { v1 as uuidv1 } from "uuid";
import { Storage, ref, uploadBytes } from "@angular/fire/storage";
import { getDownloadURL } from "firebase/storage";
import { tap } from "rxjs";
import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
  limit,
  orderBy,
} from "@angular/fire/firestore";
import heic2any from "heic2any";

export class SendNewReport {
  static readonly type = "[Report] SendNewReport";
  constructor(
    public readonly report: Report,
    public readonly reportSettings: ReportSettings,
    public readonly photos: Photo[],
    public readonly onSuccess: (id: string) => void
  ) {}
}

export class GetReportById {
  static readonly type = "[Reports] GetReportById";
  constructor(public readonly id: string) {}
}

export class GetReports {
  static readonly type = "[Report] GetReports";
  constructor(
    public readonly client: string,
    public readonly number: number,
    public readonly asset: string,
    public readonly startDate: number,
    public readonly endDate: number,
    public readonly limitAmount: number
  ) {}
}

export class EditReport {
  static readonly type = "[Report] EditReport]";
  constructor(
    public readonly id: string,
    public readonly report: Report,
    public readonly photos: Photo[]
  ) {}
}

export class DeleteReport {
  static readonly type = "[Report] DeleteReport]";
  constructor(public readonly idReport: any) {}
}

export class GetReportSettings {
  static readonly type = "[ReportSettings] GetReportSettings]";
  constructor() {}
}

export class EditReportSettings {
  static readonly type = "[ReportSettings] EditReportSettings]";
  constructor(
    public readonly id: string,
    public readonly reportSettings: ReportSettings
  ) {}
}

const reportsCollection = environment.production ? "reports" : "dev-reports";
const reportInformationCollection = environment.production
  ? "reportsInformation"
  : "dev-reportsInformation";

const reportImages = environment.production ? "images" : "dev-images";

@State<Report>({
  name: "report",
})
@Injectable()
export class ReportState {
  private firestore: Firestore = inject(Firestore);

  constructor(
    private afs: AngularFirestore,
    private snack: MatSnackBar,
    private store: Store,
    private storage: Storage
  ) {}

  ngxsOnInit({ dispatch }: StateContext<Report>) {}

  @Selector()
  static getReportById({ report }: Report) {
    return report;
  }

  @Selector()
  static getReports({ reports }: Report): Report[] {
    return reports;
  }

  @Selector()
  static getReportSettings({ reportSettings }: ReportSettings): ReportSettings {
    return reportSettings;
  }

  async convertFileToBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          resolve(arrayBuffer);
        } else {
          reject(new Error("Error al leer el archivo."));
        }
      };

      reader.readAsArrayBuffer(file);
    });
  }

  async uploadImages(photos: Photo[], reportNumber: number): Promise<string[]> {
    const downloadURL = [];

    for (const photo of photos) {
      if (photo.file) {
        const uuidImage = uuidv1();
        const FirebaseId = `${reportImages}/${reportNumber}/${uuidImage}${Date.now()}`;
        const fileRef = ref(this.storage, FirebaseId);

        if (photo.file.type === "image/heic") {
          try {
            const buffer = await this.convertFileToBuffer(photo.file);
            try {
              const jpegBuffer = await this.convertHEICtoJPEG(buffer);
              await uploadBytes(fileRef, jpegBuffer);
              const link = await getDownloadURL(fileRef);
              downloadURL.push(link);
              this.snack.open(
                "Imagen HEIC convertida y subida correctamente",
                "Ok",
                {
                  duration: 3000,
                  panelClass: ["snackbar"],
                  horizontalPosition: "center",
                  verticalPosition: "bottom",
                }
              );
            } catch (conversionError) {
              this.snack.open(
                "Error al convertir HEIC a JPEG: " + conversionError.message,
                "Ok",
                {
                  duration: 3000,
                  panelClass: ["snackbar-error"],
                  horizontalPosition: "center",
                  verticalPosition: "bottom",
                }
              );
            }
          } catch (readError) {
            this.snack.open(
              "Error al leer el archivo HEIC: " + readError.message,
              "Ok",
              {
                duration: 3000,
                panelClass: ["snackbar-error"],
                horizontalPosition: "center",
                verticalPosition: "bottom",
              }
            );
          }
        } else {
          try {
            await uploadBytes(fileRef, photo.file);
            const link = await getDownloadURL(fileRef);
            downloadURL.push(link);
            this.snack.open("Imagen subida correctamente", "Ok", {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          } catch (uploadError) {
            this.snack.open(
              "Error al subir la imagen: " + uploadError.message,
              "Ok",
              {
                duration: 3000,
                panelClass: ["snackbar-error"],
                horizontalPosition: "center",
                verticalPosition: "bottom",
              }
            );
          }
        }
      }
    }

    return downloadURL;
  }

  async convertHEICtoJPEG(heicBuffer: ArrayBuffer): Promise<Buffer> {
    const heicBlob = new Blob([heicBuffer], { type: "image/heic" });
    const jpegBlob = await heic2any({
      blob: heicBlob,
      toType: "image/jpeg",
    });

    const blobArray = Array.isArray(jpegBlob) ? jpegBlob : [jpegBlob];
    const concatenatedBlob = new Blob(blobArray, { type: "image/jpeg" });
    const jpegBuffer = await new Response(concatenatedBlob).arrayBuffer();
    return Buffer.from(jpegBuffer);
  }

  @Action(SendNewReport)
  async sendNewReport(
    {}: StateContext<Report>,
    { report, reportSettings, photos, onSuccess }: SendNewReport
  ) {
    const id = this.afs.createId();
    const dateform = new Date();
    report.createdAt = dateform.toLocaleString("en-GB");
    report.timestamp = new Date().getTime();
    if (photos.length > 0) {
      try {
        const data = await this.uploadImages(
          photos,
          reportSettings.reportNumber
        );
        if (data) {
          report.photosURL = data;
          try {
            const reportNumberRef: AngularFirestoreDocument<any> = this.afs
              .collection(reportInformationCollection)
              .doc(reportSettings.id);

            try {
              await this.afs.firestore.runTransaction(async (t) => {
                const doc = await t.get(reportNumberRef.ref);

                const newReportNumber = doc.data()?.reportNumber + 1;
                if (newReportNumber !== undefined) {
                  t.update(reportNumberRef.ref, {
                    reportNumber: newReportNumber,
                  });

                  await this.afs
                    .collection(reportsCollection)
                    .doc(id)
                    .set({
                      id,
                      ...report,
                      reportNumber: newReportNumber,
                    });
                } else {
                  this.snack.open("Ha ocurrido un error", "Ok", {
                    duration: 3000,
                    panelClass: ["snackbar-error"],
                    horizontalPosition: "center",
                    verticalPosition: "bottom",
                  });
                }
              });
            } catch (e) {
              this.snack.open("Ha ocurrido un error", "Ok", {
                duration: 3000,
                panelClass: ["snackbar-error"],
                horizontalPosition: "center",
                verticalPosition: "bottom",
              });
            }
            this.store.dispatch(new Navigate([`/report/${id}`]));
            onSuccess(id);

            this.snack.open("¡Reporte creado correctamente!", "Ok", {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          } catch {
            this.snack.open("Ha ocurrido un error", "Ok", {
              duration: 3000,
              panelClass: ["snackbar-error"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          }
        }
      } catch {
        this.snack.open("Ha ocurrido un error", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    } else {
      try {
        const reportNumberRef: AngularFirestoreDocument<any> = this.afs
          .collection(reportInformationCollection)
          .doc(reportSettings.id);

        try {
          await this.afs.firestore.runTransaction(async (t) => {
            const doc = await t.get(reportNumberRef.ref);

            const newReportNumber = doc.data()?.reportNumber + 1;

            if (newReportNumber !== undefined) {
              t.update(reportNumberRef.ref, {
                reportNumber: newReportNumber,
              });

              await this.afs
                .collection(reportsCollection)
                .doc(id)
                .set({
                  id,
                  ...report,
                  reportNumber: newReportNumber,
                });
            } else {
              this.snack.open("Ha ocurrido un error", "Ok", {
                duration: 3000,
                panelClass: ["snackbar-error"],
                horizontalPosition: "center",
                verticalPosition: "bottom",
              });
            }
          });
        } catch (e) {
          this.snack.open("Ha ocurrido un error", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }

        this.store.dispatch(new Navigate([`/report/${id}`]));
        onSuccess(id);

        this.snack.open("¡Reporte creado correctamente!", "Ok", {
          duration: 3000,
          panelClass: ["snackbar"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      } catch {
        this.snack.open("Ha ocurrido un error", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    }
  }

  @Action(GetReportById)
  getReportById({ patchState }: StateContext<Report>, { id }: GetReportById) {
    const subs = this.afs
      .collection<Report>(reportsCollection)
      .doc(id)
      .valueChanges()
      .subscribe((report) => {
        patchState({ report });
        subs.unsubscribe();
      });
  }

  @Action(GetReports)
  async getReports(
    { patchState, getState }: StateContext<Report>,
    { client, number, asset, startDate, endDate, limitAmount }: GetReports
  ) {
    const { reports } = getState();

    let q = query(collection(this.firestore, reportsCollection));

    if (client) q = query(q, where("client", "==", client));
    if (number) q = query(q, where("reportNumber", "==", number));
    if (asset) q = query(q, where("asset.code", "==", asset));
    if (startDate && endDate) {
      q = query(q, where("timestamp", ">=", startDate));
      q = query(q, where("timestamp", "<", endDate));
    }
    if (!startDate && !endDate) {
      q = query(q, orderBy("reportNumber", "desc"));
    } else {
      q = query(q, orderBy("timestamp", "desc"));
    }

    if (limitAmount) q = query(q, limit(limitAmount));

    const qSnapshot = await getDocs(q);
    const data: Report[] = qSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
      } as Report;
    });

    patchState({ reports: data });
  }

  @Action(EditReport)
  async EditReport(
    {}: StateContext<Report>,
    { id, report, photos }: EditReport
  ) {
    const URLs = [];
    const dateform = new Date();
    report.updatedAt = dateform.toLocaleString("en-GB");
    let newPhoto = false;
    for (const photo of photos) {
      if (photo.file) {
        newPhoto = true;
      } else {
        URLs.push(photo.url);
      }
    }
    if (newPhoto) {
      try {
        const data = await this.uploadImages(photos, report.reportNumber);
        if (data) {
          data.push(...URLs);
          report.photosURL = data;
          try {
            await this.afs
              .collection(reportsCollection)
              .doc(id)
              .update({
                ...report,
              });
            this.snack.open("¡Reporte actualizado correctamente!", "Ok", {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
            this.store.dispatch(new Navigate(["/report-view"]));
          } catch {
            this.snack.open("Ha ocurrido un error", "Ok", {
              duration: 3000,
              panelClass: ["snackbar-error"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          }
        }
      } catch {
        this.snack.open("Ha ocurrido un error", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    } else {
      report.photosURL = URLs;
      try {
        await this.afs
          .collection(reportsCollection)
          .doc(id)
          .update({
            ...report,
          });
        this.snack.open("¡Reporte actualizado correctamente!", "Ok", {
          duration: 3000,
          panelClass: ["snackbar"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        this.store.dispatch(new Navigate(["/report-view"]));
      } catch {
        this.snack.open("Ha ocurrido un error", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    }
  }

  @Action(DeleteReport)
  DeleteReport({}: StateContext<Report>, { idReport }: DeleteReport) {
    this.afs.collection(reportsCollection).doc(idReport).delete();
  }

  @Action(GetReportSettings)
  GetReportSettings(
    { patchState, getState }: StateContext<ReportSettings>,
    {}: GetReportSettings
  ) {
    const { reportSettings } = getState();
    if (!reportSettings)
      return this.afs
        .collection<ReportSettings>(reportInformationCollection)
        .valueChanges()
        .pipe(
          tap((reportSettings) =>
            patchState({ reportSettings: reportSettings[0] })
          )
        );
    return null;
  }

  @Action(EditReportSettings)
  async EditReportSettings(
    {}: StateContext<ReportSettings>,
    { id, reportSettings }: EditReportSettings
  ) {
    try {
      await this.afs
        .collection(reportInformationCollection)
        .doc(id)
        .update({
          ...reportSettings,
        });
      this.snack.open("¡Información actualizada correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
