import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClientPermissionGuard } from "./guards/client-permission.guard";
import { CreateReportPermissionGuard } from "./guards/create-report-permission.guard";
import { LoggedInGuard } from "./guards/logged-in.guard";
import { NotLoggedInGuard } from "./guards/not-logged-in.guard";
import { UserPermissionGuard } from "./guards/user-permission.guard";
import { ViewReportPermissionGuard } from "./guards/view-report-permission.guard";
import { CreateAssetsPermissionGuard } from "./guards/create-assets-permission.guard";
import { CreateSubtasksPermissionGuard } from "./guards/create-subtask-permission.guard";
import { MaintenancePermissionGuard } from "./guards/maintenance-permission.guard";
import { SendMailsPermissionGuard } from "./guards/send-mails-permission.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((l) => l.LoginModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: "clients",
    loadChildren: () =>
      import("./pages/clients/clients.module").then((l) => l.ClientsModule),
    canActivate: [NotLoggedInGuard, ClientPermissionGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((l) => l.HomeModule),
    canActivate: [NotLoggedInGuard],
  },
  {
    path: "new-client",
    loadChildren: () =>
      import("./pages/new-client/new-client.module").then(
        (l) => l.NewClientModule
      ),
    canActivate: [NotLoggedInGuard, ClientPermissionGuard],
  },
  {
    path: "users",
    loadChildren: () =>
      import("./pages/users/users.module").then((l) => l.UsersModule),
    canActivate: [NotLoggedInGuard, UserPermissionGuard],
  },
  {
    path: "report",
    loadChildren: () =>
      import("./pages/report/report.module").then((l) => l.ReportModule),
    canActivate: [NotLoggedInGuard, CreateReportPermissionGuard],
  },
  {
    path: "report-view",
    loadChildren: () =>
      import("./pages/see-report/see-report.module").then(
        (l) => l.SeeReportModule
      ),
    canActivate: [NotLoggedInGuard, ViewReportPermissionGuard],
  },
  {
    path: "new-user",
    loadChildren: () =>
      import("./pages/new-user/new-user.module").then((l) => l.NewUserModule),
    canActivate: [NotLoggedInGuard, UserPermissionGuard],
  },
  {
    path: "maintenance",
    loadChildren: () =>
      import("./pages/maintenance/maintenance.module").then(
        (l) => l.MaintenanceModule
      ),
    canActivate: [NotLoggedInGuard, MaintenancePermissionGuard],
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("./pages/calendar/calendar.module").then((l) => l.CalendarModule),
    canActivate: [NotLoggedInGuard],
  },
  {
    path: "subtasks",
    loadChildren: () =>
      import("./pages/subtasks/subtasks.module").then((l) => l.SubtasksModule),
    canActivate: [NotLoggedInGuard, CreateSubtasksPermissionGuard],
  },
  {
    path: "assets",
    loadChildren: () =>
      import("./pages/assets/assets.module").then((l) => l.AssetsModule),
    canActivate: [NotLoggedInGuard, CreateAssetsPermissionGuard],
  },
  {
    path: "send-mails",
    loadChildren: () =>
      import("./pages/mail/mail.module").then((l) => l.MailModule),
    canActivate: [NotLoggedInGuard, SendMailsPermissionGuard],
  },
  {
    path: "download-pdf",
    loadChildren: () =>
      import("./pages/dowload-pdf/download-pdf.module").then(
        (l) => l.DownloadPdfModule
      ),
  },
  {
    path: "app",
    loadChildren: () =>
      import("./pages/terms/terms.module").then(
        (l) => l.TermsModule
      ),
  },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
