import { Action, State, StateContext, Store, Selector } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { User, LoggedInUser } from "../interfaces/user";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { tap } from "rxjs";
const usersCollection = environment.production ? "users" : "dev-users";

export class Login {
  static readonly type = "[UserCredentials] Login";
  constructor(
    public readonly credentials: { username: string; password: string },
    public readonly onFinish: () => void
  ) {}
}

export class Logout {
  static readonly type = "[] Login";
  constructor() {}
}

export class SendNewUser {
  static readonly type = "[User] SendNewUser]";
  constructor(public readonly user: User) {}
}

export class GetUserById {
  static readonly type = "[User] GetUserById";
  constructor(public readonly id: string) {}
}

export class GetUsers {
  static readonly type = "[User] GetUser";
  constructor() {}
}

export class EditUser {
  static readonly type = "[User] EditUser]";
  constructor(public readonly id: string, public readonly user: User) {}
}

export class DeleteUser {
  static readonly type = "[Report] DeleteUser]";
  constructor(public readonly idUser: any) {}
}

@State<User>({
  name: "user",
})
@Injectable()
export class UserState {
  @Selector()
  static getUserById({ user }: User) {
    return user;
  }

  @Selector()
  static getUsers({ users }: User): User[] {
    return users;
  }

  @Selector()
  static loggedInUser({ loggedInUser }: LoggedInUser) {
    return loggedInUser;
  }

  public session = null;

  constructor(
    private store: Store,
    private afAuth: AngularFireAuth,
    private snack: MatSnackBar,
    private afs: AngularFirestore,
    private cookieService: CookieService
  ) {}

  ngxsOnInit({}: StateContext<User>) {
    this.afAuth.useDeviceLanguage();
  }

  @Action(Login)
  login(
    { patchState, dispatch }: StateContext<LoggedInUser>,
    { credentials, onFinish }: Login
  ) {
    if (credentials) {
      const { username, password } = credentials;
      this.session = this.afs
        .collection(usersCollection, (ref) =>
          ref
            .where("username", "==", username)
            .where("password", "==", password)
        )
        .valueChanges()
        .subscribe((res: User[]) => {
          if (res[0]) {
            this.cookieService.set(
              "user",
              JSON.stringify(res[0]),
              undefined,
              undefined,
              undefined,
              true,
              "Strict"
            );
            patchState({ loggedInUser: res[0] });
            dispatch(new Navigate(["/home"]));
          } else {
            this.snack.open("Usuario o contraseña incorrecta", "OK", {
              duration: 3000,
              panelClass: ["snackbar-error"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            });
          }
          onFinish();
        });
    }
  }

  @Action(Logout)
  Logout({}: StateContext<LoggedInUser>) {
    this.session?.unsubscribe();
    this.cookieService.delete("user");
    this.store.dispatch(new Navigate(["/login"]));
  }

  @Action(SendNewUser)
  async SendNewUser({}: StateContext<User>, { user }: SendNewUser) {
    const id = this.afs.createId();
    const dateform = new Date();
    user.createdAt = dateform.toLocaleString("en-GB");
    try {
      await this.afs
        .collection(usersCollection)
        .doc(id)
        .set({
          id,
          ...user,
        });
      this.snack.open("¡Usuario creado correctamente!", "OK", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "OK", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(GetUserById)
  getUserById({ patchState }: StateContext<User>, { id }: GetUserById) {
    const subs = this.afs
      .collection(usersCollection)
      .doc(id)
      .valueChanges()
      .subscribe((user: User) => {
        patchState({ user: user });
        subs.unsubscribe();
      });
  }

  @Action(GetUsers)
  getUser({ patchState, getState }: StateContext<User>, {}: GetUsers) {
    const { users } = getState();
    if (!users?.length)
      return this.afs
        .collection<User>(usersCollection)
        .valueChanges()
        .pipe(tap((user) => patchState({ users: user })));
    return null;
  }

  @Action(EditUser)
  async EditUser({}: StateContext<User>, { id, user }: EditUser) {
    const dateform = new Date();
    const {
      username,
      password,
      clientsPermission,
      createReportPermission,
      usersPermission,
      viewReportPermission,
      createAssetsPermission,
      createSubtasksPermission,
    } = user;
    try {
      await this.afs
        .collection(usersCollection)
        .doc(id)
        .update({
          id,
          username,
          password,
          clientsPermission,
          createReportPermission,
          usersPermission,
          viewReportPermission,
          createAssetsPermission,
          createSubtasksPermission,
          updatedAt: dateform.toLocaleString("en-GB"),
        });
      this.snack.open("¡Usuario actualizado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(DeleteUser)
  async DeleteUser({}: StateContext<User>, { idUser }: DeleteUser) {
    try {
      await this.afs.collection(usersCollection).doc(idUser).delete();
      this.snack.open("¡Usuario borrado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
