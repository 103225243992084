import { Action, State, StateContext, Selector, Store } from "@ngxs/store";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Navigate } from "@ngxs/router-plugin";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";
import { Mail } from "../interfaces/mail";
import { environment } from "../../environments/environment";

export class SendNewMails {
  static readonly type = "[Mail] SendNewMails";
  constructor(public readonly mails: Mail[]) {}
}

export class GetMails {
  static readonly type = "[Mail] GetMails";
  constructor() {}
}

export class DeleteMail {
  static readonly type = "[Mail] DeleteMail]";
  constructor(public readonly idMail: any) {}
}

const mailsCollection = environment.production ? "mails" : "dev-mails";

@State<Mail>({
  name: "mail",
})
@Injectable()
export class MailState {
  constructor(
    private afs: AngularFirestore,
    private snack: MatSnackBar,
    private store: Store,
    private cookieService: CookieService
  ) {}

  @Selector()
  static getMails({ mails }: Mail): Mail[] {
    return mails;
  }

  @Action(SendNewMails)
  sendNewMails({}: StateContext<Mail>, { mails }: SendNewMails) {
    mails.forEach((mail) => {
      const id = this.afs.createId();
      mail.id = id;
      const dateform = new Date();
      mail.createdAt = dateform.toLocaleString("en-GB");
      const user: User = JSON.parse(this.cookieService.get("user"));
      mail.createdBy = user.username;
      this.afs
        .collection(mailsCollection)
        .doc(id)
        .set({
          ...mail,
        })
        .catch((error) => {
          this.snack.open("Ha ocurrido un error", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        });
    });
    this.snack.open("¡Correos agregados correctamente!", "Ok", {
      duration: 3000,
      panelClass: ["snackbar"],
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
    this.store.dispatch(new Navigate(["/send-mails"]));
  }

  @Action(GetMails)
  getMails({ patchState }: StateContext<Mail>, {}: GetMails) {
    this.afs
      .collection(mailsCollection)
      .valueChanges()
      .subscribe((mails: Mail[]) => {
        patchState({ mails: mails });
      });
  }

  @Action(DeleteMail)
  deleteMail({}: StateContext<Mail>, { idMail }: DeleteMail) {
    this.afs
      .collection(mailsCollection)
      .doc(idMail)
      .delete()
      .then(() => {
        this.snack.open("¡Correo borrado correctamente!", "Ok", {
          duration: 3000,
          panelClass: ["snackbar"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      })
      .catch(() => {
        this.snack.open("Ha ocurrido un error", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      })
  }
}
