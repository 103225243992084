import { Action, State, StateContext, Selector, Store } from "@ngxs/store";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { Asset } from "../interfaces/asset";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { Navigate } from "@ngxs/router-plugin";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";

export class SendNewAsset {
  static readonly type = "[Asset] SendNewAsset";
  constructor(
    public readonly asset: Asset,
    public readonly onSave: () => void
  ) {}
}

export class GetAssets {
  static readonly type = "[Asset] GetAssets";
  constructor(public readonly limit: number) {}
}

export class GetAssetsByClientId {
  static readonly type = "[Asset] GetAssetsByClientId";
  constructor(
    public readonly idClient: string,
    public readonly limit: number
  ) {}
}

export class GetAssetsByCodeAndClientId {
  static readonly type = "[Asset] GetAssetsByCodeAndClientId";
  constructor(public readonly idClient: string, public readonly code: string) {}
}

export class GetAssetById {
  static readonly type = "[Asset] GetAssetById";
  constructor(public readonly id: string) {}
}

export class EditAsset {
  static readonly type = "[Asset] EditAsset]";
  constructor(
    public readonly id: string,
    public readonly asset: Asset,
    public readonly onUpdate: () => void
  ) {}
}

export class DeleteAsset {
  static readonly type = "[Asset] DeleteAsset]";
  constructor(
    public readonly idAsset: any,
    public readonly onDelete: () => void
  ) {}
}

export class CleanAssetByCodeAndClient {
  static readonly type = "[Asset] CleanAssetByCodeAndClient]";
  constructor() {}
}

const assetsCollection = environment.production ? "assets" : "dev-assets";

@State<Asset>({
  name: "asset",
})
@Injectable()
export class AssetState {
  constructor(
    private afs: AngularFirestore,
    private snack: MatSnackBar,
    private store: Store,
    private cookieService: CookieService
  ) {}

  @Selector()
  static getAssets({ assets }: Asset): Asset[] {
    return assets;
  }

  @Selector()
  static getAssetsById({ asset }: Asset): Asset {
    return asset;
  }

  @Selector()
  static getAssetsByCodeAndClientId({ assetByClientAndCode }: Asset): Asset[] {
    return assetByClientAndCode;
  }

  @Action(SendNewAsset)
  async sendNewAsset({}: StateContext<Asset>, { asset, onSave }: SendNewAsset) {
    const id = this.afs.createId();
    const dateform = new Date();
    asset.createdAt = dateform.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));
    asset.createdBy = user.username;
    try {
      await this.afs
        .collection(assetsCollection)
        .doc(id)
        .set({
          id,
          ...asset,
        });
      this.snack.open("¡Activo creado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(GetAssetsByCodeAndClientId)
  getAssetsByCodeAndClientId(
    { patchState }: StateContext<Asset>,
    { idClient, code }: GetAssetsByCodeAndClientId
  ) {
    const subs = this.afs
      .collection(assetsCollection, (ref) =>
        ref.where("clientId", "==", idClient).where("code", "==", code)
      )
      .valueChanges()
      .subscribe((assets: Asset[]) => {
        if (assets.length === 0) {
          this.snack.open("No se encontró un activo", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
        patchState({ assetByClientAndCode: assets });
        subs.unsubscribe();
      });
  }

  @Action(GetAssetsByClientId)
  getAssetsByClientId(
    { patchState }: StateContext<Asset>,
    { idClient, limit }: GetAssetsByClientId
  ) {
    const subs = this.afs
      .collection<Asset>(assetsCollection, (ref) =>
        ref.where("clientId", "==", idClient).limit(limit)
      )
      .valueChanges()
      .subscribe((assets) => {
        patchState({ assets });
        subs.unsubscribe();
      });
  }

  @Action(GetAssets)
  getAssets({ patchState }: StateContext<Asset>, { limit }: GetAssets) {
    const subs = this.afs
      .collection(assetsCollection, (ref) => ref.limit(limit))
      .valueChanges()
      .subscribe((assets: Asset[]) => {
        patchState({ assets: assets });
        subs.unsubscribe();
      });
  }

  @Action(GetAssetById)
  getAssetById({ patchState }: StateContext<Asset>, { id }: GetAssetById) {
    const subs = this.afs
      .collection<Asset>(assetsCollection)
      .doc(id)
      .valueChanges()
      .subscribe((asset) => {
        patchState({ asset });
        subs.unsubscribe();
      });
  }

  @Action(EditAsset)
  async editAsset({}: StateContext<Asset>, { id, asset, onUpdate }: EditAsset) {
    const dateform = new Date();
    asset.updatedAt = dateform.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));
    asset.updatedBy = user.username;
    try {
      await this.afs
        .collection(assetsCollection)
        .doc(id)
        .update({
          id,
          ...asset,
        });
      this.snack.open("¡Activo actualizado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
      onUpdate();
      this.store.dispatch(new Navigate(["/assets"]));
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(DeleteAsset)
  async deleteAsset(
    {}: StateContext<Asset>,
    { idAsset, onDelete }: DeleteAsset
  ) {
    try {
      await this.afs.collection(assetsCollection).doc(idAsset).delete();
      this.snack.open("¡Activo borrado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } finally {
      onDelete();
    }
  }

  @Action(CleanAssetByCodeAndClient)
  cleanAssets(
    { patchState }: StateContext<Asset>,
    {}: CleanAssetByCodeAndClient
  ) {
    patchState({ assetByClientAndCode: [] });
  }
}
