import { Action, State, StateContext, Selector } from "@ngxs/store";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { Client } from "../interfaces/client";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { tap } from "rxjs";

export class SendNewClient {
  static readonly type = "[Client] SendNewClient";
  constructor(public readonly client: Client) {}
}

export class GetClients {
  static readonly type = "[Client] GetClients";
  constructor() {}
}

export class GetClientById {
  static readonly type = "[Client] GetClientById";
  constructor(public readonly id: string) {}
}

export class EditClient {
  static readonly type = "[Client] EditClient]";
  constructor(public readonly id: string, public readonly client: Client) {}
}

const clientsCollection = environment.production ? "clients" : "dev-clients";

@State<Client>({
  name: "client",
})
@Injectable()
export class ClientState {
  constructor(private afs: AngularFirestore, private snack: MatSnackBar) {}

  @Selector()
  static getClients({ clients }: Client): Client[] {
    return clients;
  }

  @Selector()
  static getClientById({ client }: Client): Client {
    return client;
  }

  @Action(SendNewClient)
  async SendNewClient({}: StateContext<Client>, { client }: SendNewClient) {
    const id = this.afs.createId();
    const dateform = new Date();
    client.createdAt = dateform.toLocaleString("en-GB");
    try {
      await this.afs
        .collection(clientsCollection)
        .doc(id)
        .set({
          id,
          ...client,
        });
      this.snack.open("¡Cliente creado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(GetClients)
  getClients({ patchState, getState }: StateContext<Client>, {}: GetClients) {
    const { clients } = getState();
    if (!clients?.length)
      return this.afs
        .collection<Client>(clientsCollection)
        .valueChanges()
        .pipe(tap((clientsData) => patchState({ clients: clientsData })))
        .subscribe();
    return null;
  }

  @Action(GetClientById)
  getClientById({ patchState }: StateContext<Client>, { id }: GetClientById) {
    const subs = this.afs
      .collection<Client>(clientsCollection)
      .doc(id)
      .valueChanges()
      .pipe(
        tap((client) => {
          patchState({ client });
          subs?.unsubscribe();
        })
      )
      .subscribe();
  }

  @Action(EditClient)
  EditClient({}: StateContext<Client>, { id, client }: EditClient) {
    const dateform = new Date();
    const {
      companyName,
      responsableName,
      phone,
      email,
      province,
      canton,
      district,
      otherSignal,
    } = client;

    try {
      this.afs
        .collection(clientsCollection)
        .doc(id)
        .update({
          id,
          companyName,
          responsableName,
          phone,
          email,
          province,
          canton,
          district,
          otherSignal,
          updatedAt: dateform.toLocaleString("en-GB"),
        });
      this.snack.open("¡Cliente actualizado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snack.open("Ha ocurrido un error", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
