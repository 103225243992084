export const environment = {
  production: false,
  firebase: {
    projectId: "tecno-air-cold",
    appId: "1:152294993889:web:4cef4fd4cc081fbeef2ca0",
    storageBucket: "tecno-air-cold.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyCQinoMPf1--UO_w_tY_aKNsHpU21NkADY",
    authDomain: "tecno-air-cold.firebaseapp.com",
    messagingSenderId: "152294993889",
    measurementId: "G-17CE89E5R4",
  },
  encryptKey: "3#Xqn!89kt",

  web: {
    client_id:
      "152294993889-s2aojj1q842fogkrp8dct3qpo4ku7iir.apps.googleusercontent.com",
    project_id: "tecno-air-cold",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_secret: "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",
    redirect_uris: [
      "https://tecno-air-cold.firebaseapp.com/__/auth/handler",
      "https://developers.google.com/oauthplayground",
    ],
    javascript_origins: [
      "http://localhost",
      "http://localhost:5000",
      "https://tecno-air-cold.firebaseapp.com",
    ],
  },
  refresh_token:
    "1//04SxXe7etE8ZCCgYIARAAGAQSNwF-L9IrjUPvqPEJZ1DYIPp5f-UnQ3QMFrjAaS6lQZ7KVrZAGopVBEesB6AI_fg2BSIgnUiSNa0",
};
