import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";

@Injectable({
  providedIn: "root",
})
export class ViewReportPermissionGuard implements CanActivate {
  constructor(private cookieService: CookieService, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const user: User = JSON.parse(this.cookieService.get("user"));

    if (user.viewReportPermission) {
      return true;
    } else {
      this.store.dispatch(new Navigate(["/home"]));
      return false;
    }
  }
}
