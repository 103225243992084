import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngxs/store";
import { Logout } from "./states/user.state";
import { User } from "./interfaces/user";
import { MatDialog } from "@angular/material/dialog";
import { EditReportInformationModalComponent } from "./pages/report/components/edit-report-information-modal/edit-report-information-modal.component";
import { calculateWidth } from "./helpers/calculate-modal-width";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  title = "tecno-air-cold";

  constructor(
    private location: Location,
    private cookieService: CookieService,
    private store: Store,
    private MatDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.routeHome();
  }

  goBack() {
    this.location.back();
  }

  isloggedIn() {
    return this.cookieService.get("user") ? true : false;
  }

  getUser(): User {
    if (this.isloggedIn()) {
      return JSON.parse(this.cookieService.get("user"));
    }
    return null;
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  routeHome() {
    if (window.location.pathname === "/home") {
      return true;
    } else {
      return false;
    }
  }

  openEditHeaderInformationDialog = () => {
    this.MatDialog.open(EditReportInformationModalComponent, {
      maxWidth: 'auto',
      width: 'auto',
    });
  };
}
