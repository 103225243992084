import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterSubtask",
})
export class FilterSubtaskPipe implements PipeTransform {
  transform(array: any[], filterString: string): any[] {
    const filterList: any[] = [];
    if (!array || !filterString) {
      return array;
    }

    for (let data of array) {
      if (
        data["description"].toLowerCase().includes(filterString.toLowerCase())
      ) {
        filterList.push(data);
      }
    }
    return filterList;
  }
}
