import { ReportAsset } from "./asset";
import { ReportSubtask, Subtask } from "./subtask";

export interface Report {
  get(arg0: string): unknown;
  patchValue(arg0: { typeOption: null }): unknown;
  reports?: Report[];
  report?: Report;
}

export interface Report {
  reportNumber?: number;
  responsableName: string;
  client: string;
  clientName: string;
  email: string;
  address: string;
  date: string;
  description: string;
  condenser?: boolean;
  evaporator?: boolean;
  type: string;
  typeOption: string;
  priority: string;
  preventive: boolean;
  corrective: boolean;
  installation: boolean;
  gas: string;
  subtaks: ReportSubtask[];
  nextVisit: NextVisit;
  asset: ReportAsset;
  equipment?: string;
  photosURL?: string[];
  timestamp: number;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface TypeOption {
  id: string;
  name: string;
}

export interface NextVisit {
  months: string;
  date: string;
}

export enum ValueTypes {
  MULTIPLE = "multiple",
  STRING = "string",
}

export interface ReportSettings {
  id: string;
  address: string;
  phone: string;
  reportNumber: number;
}

export interface ReportSettings {
  reportSettings?: ReportSettings;
}

export interface Photo {
  file?: File;
  url: string;
}

export const evaporatorList: TypeOption[] = [
  { id: "1", name: "Cassette 4 vías" },
  { id: "2", name: "Cassette 1 vía" },
  { id: "3", name: "Fancoil" },
  { id: "4", name: "Central de ductos" },
  { id: "5", name: "Pared alta" },
  { id: "6", name: "Piso cielo" },
];

export const condenserList: TypeOption[] = [
  { id: "1", name: "Descarga vertical" },
  { id: "2", name: "Descarga horizontal" },
  { id: "3", name: "Vrf" },
  { id: "4", name: "Mini Vrf" },
  { id: "5", name: "Mini split" },
  { id: "6", name: "Multi split" },
];

export const packList: TypeOption[] = [{ id: "1", name: "Paquete" }];
