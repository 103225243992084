import { Pipe, PipeTransform } from "@angular/core";
import { getDate } from "../helpers/getDate";

@Pipe({
  name: "filterDateCalendar",
})
export class FilterDateCalendarPipe implements PipeTransform {
  transform(array: any[], dateToFilter: string): any[] {
    const filteredArray = [];
    if (dateToFilter) {
      const filterDate = new Date(dateToFilter);

      for (const data of array) {
        if (data.nextVisit?.date) {
          const date = getDate(data.nextVisit?.date);
          if (this.isSameDate(date, filterDate)) {
            filteredArray.push(data);
          }
        }
      }

      return filteredArray;
    }
    return array;
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}
