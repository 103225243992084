import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Select, Store } from "@ngxs/store";
import {
  EditReportSettings,
  ReportState,
} from "src/app/states/report.state";
import { GetReportSettings } from "../../../../states/report.state";
import { ReportSettings } from "../../../../interfaces/report";
import { Observable } from "rxjs";

@Component({
  selector: "app-edit-report-information-modal",
  templateUrl: "./edit-report-information-modal.component.html",
  styleUrls: ["./edit-report-information-modal.component.less"],
})
export class EditReportInformationModalComponent implements OnInit {
  @Select(ReportState.getReportSettings)
  GetReportSettings: Observable<ReportSettings>;

  public reportHeaderForm: FormGroup;
  public id: string;
  public loading = false;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matRef: MatDialogRef<EditReportInformationModalComponent>,
    private snack: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(new GetReportSettings());
    this.reportHeaderForm = this.fb.group({
      address: [null, [Validators.required]],
      phone: [null, [Validators.required]],
    });
    this.GetReportSettings.subscribe((response) => {
      if (response) {
        this.id = response.id;
        this.reportHeaderForm.patchValue({
          address: response.address,
          phone: response.phone,
        });
        this.loading = false;
      }
    });
  }

  closeDialog = () => this.matRef.close();

  saveReportHeaderInformation = () => {
    this.store.dispatch(
      new EditReportSettings(this.id, this.reportHeaderForm.value)
    );
    this.closeDialog();
  };
}
