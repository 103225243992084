import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterReportNumber",
})
export class FilterReportNumberPipe implements PipeTransform {
  transform(array: any[], filterNumber: number): any[] {
    if (!array || !filterNumber) {
      return array;
    }
    let filtered: any[] = [];
    for (let data of array) {
      if (data["reportNumber"] && data["reportNumber"] === filterNumber) {
        filtered.push(data);
      }
    }
    return filtered;
  }
}
